/* eslint-disable react-hooks/exhaustive-deps */
import React, { FunctionComponent, useEffect, useState, useCallback, useRef } from "react";

import {
  MenuItem,
  MenuList,
  TableCell,
  TableRow,
  Button,
  ButtonGroup,
  Popper,
  ClickAwayListener,
  Grow,
  Paper,
  Typography,
  TextField,
} from "@material-ui/core";

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import {
  Autorenew as AutorenewIcon,
  ArrowDropDown as ArrowDropDownIcon,
  ArrowBackIos as ArrowBackIcon,
} from "@material-ui/icons";

import TableComponent from "../../components/common/table/table.component";
import PaperComponent from "../../components/paper/paper.component";
import { CaptionElement, TelemetryCaption, TelemetryEntry } from "../../store/telemetry/telemetry.store";
import AppButton from "../../components/common/button/button.component";
import { ButtonSize } from "../../shared/button-style";
import Preloader from "../../components/preloader/preloader.component";
import { FileTypes } from "../../shared/accordion-titles";
import { WRONG_TELEMETRY_FILE } from "../../shared/services/websocket-service";
import MapContainer from "../../components/map/map.container";
import { AccordionComponent } from "../../components/common/accordion/accordion.component";
import { Colors } from "../../theme/colors";
import RoutePaths from "../../routes/route-paths";
import { useHistory } from "react-router-dom";
import AuthService from "../../shared/services/auth.service";
import { APIError } from "../../api/backend-api";

export interface ConnectedState {
  telemetry: TelemetryEntry[][];
  loadTelemetryInProgress: boolean;
  caption: TelemetryCaption;
  pushNotifications: number;
  downloadInProgress: boolean;
  fileType: string;
  lastError: APIError | null;
  telemetryStatus: boolean;
  total: number;
}

export interface ConnectedDispatch {
  init: (rows: number) => any;
  load: (rows: number) => any;
  update: () => any;
  download: (isIsoxml: boolean) => any;
  resetTelemetry: () => void;
}

interface Props extends ConnectedState, ConnectedDispatch {}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableWrapper: {
      maxWidth: "1000px",
      margin: "auto",
      overflowX: "auto",
    },
    tableContainer: {
      "&>div": { maxHeight: 800 },
      "& table td, th": { fontSize: ".6rem", whiteSpace: "nowrap" },
      "& table th": { fontWeight: "bold" },
    },
    paginatorSpacer: { flex: "unset" },
    buttonIcon: {
      marginRight: "10px",
    },
    controls: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    downloadButton: {
      color: Colors.White,
    },
    buttonGroup: {
      boxShadow: "unset",
    },
    linkWrapper: {
      display: "flex",
      color: theme.palette.primary.main,
      width: "155px",
      "&:hover": {
        color: Colors.DarkGreen,
      },
    },
    linkTitle: {
      display: "inline-block",
      fontSize: "0.875rem",
      cursor: "pointer",
    },
    rowControl: {
      display: "flex",
      width: "340px",
      alignItems: "center",
      justifyContent: "space-between",
    },
    tentacleInput: {
      width: "95px",
      height: "30px",
    },
    invalidTelemetry: {
      backgroundColor: Colors.LightGrey,
    },
  })
);

const options = ["Download EFDI", "Download ISO XML"];
const INIT_ROWS_PER_PAGE = 100;

let timeout: NodeJS.Timeout;

const TelemetryComponent: FunctionComponent<Props> = (props: Props) => {
  const classes = useStyles();
  const history = useHistory();

  const {
    loadTelemetryInProgress,
    caption: { device = [], deviceelement = [], logvalues = [] },
    telemetry,
    pushNotifications,
    fileType,
    downloadInProgress,
    lastError = { code: 0, message: "" },
    telemetryStatus,
    resetTelemetry,
    total,
  } = props;

  const isEndUser = AuthService.getInstance().isEndUser();
  const [openDownloadOptions, setOpenDownloadOptions] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const [selectedDownloadOptionsIndex, setSelectedDownloadOptionsIndex] = useState(0);
  const [updateIsOn, setUpdateIsOn] = useState(false);
  const [displayedValue, setDisplayedValue] = useState(INIT_ROWS_PER_PAGE);

  useEffect(() => {
    props.init(displayedValue);

    return () => {
      clearInterval(timeout);
      resetTelemetry();
    };
  }, []);

  useEffect(() => {
    if (updateIsOn) {
      timeout = setInterval(props.update, 30000);
      props.update();
    } else {
      clearInterval(timeout);
    }
  }, [updateIsOn]);

  const onLoad = useCallback(() => {
    if (displayedValue) {
      props.load(displayedValue);
    }
  }, [displayedValue]);

  const handleInputValue = (e: any) => {
    if (!!e && !!e.target && +e.target.value <= total) {
      setDisplayedValue(+e.target.value);
    }
  };

  const handleDownload = useCallback(() => {
    props.download(selectedDownloadOptionsIndex > 0);
  }, [selectedDownloadOptionsIndex]);

  const handleMenuItemClick = useCallback(
    (event: React.MouseEvent<HTMLLIElement, MouseEvent>, index: number) => {
      setSelectedDownloadOptionsIndex(index);
      setOpenDownloadOptions(false);
    },
    []
  );

  const handleToggle = useCallback(() => {
    setOpenDownloadOptions((prevOpen) => !prevOpen);
  }, []);

  const handleClose = useCallback((event: React.MouseEvent<Document, MouseEvent>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpenDownloadOptions(false);
  }, []);

  const handleUpdate = useCallback(() => {
    setUpdateIsOn(!updateIsOn);
  }, [updateIsOn]);

  const onLeaveTelemetry = useCallback(() => {
    resetTelemetry();
    history.push(RoutePaths.DataStorage);
  }, []);

  const renderHeadRow = useCallback((data: CaptionElement[], testid: string) => {
    const isEmpty = data.reduce((result, { label }) => {
      return !result ? !!label : true;
    }, false);

    return isEmpty ? (
      <TableRow {...{ "data-testid": testid }}>
        {data.map(({ label, span }, index) => (
          <TableCell key={`${label}-${index}`} {...(span ? { colSpan: span } : {})}>
            {label}
          </TableCell>
        ))}
      </TableRow>
    ) : null;
  }, []);

  return (
    <>
      <PaperComponent isTiny={true}>
        <div className={classes.controls}>
          <div className={classes.linkWrapper} onClick={onLeaveTelemetry}>
            <ArrowBackIcon fontSize="small" />
            <Typography className={classes.linkTitle}>back to Data Storage</Typography>
          </div>
          <div className={classes.rowControl}>
            <AppButton
              handler={onLoad}
              size={ButtonSize.TINY_BUTTON}
              disabled={loadTelemetryInProgress || displayedValue < 0}
            >
              Load
            </AppButton>
            <TextField
              variant="outlined"
              value={displayedValue}
              className={classes.tentacleInput}
              type="number"
              InputLabelProps={{ shrink: true }}
              InputProps={{
                className: classes.tentacleInput,
              }}
              onChange={handleInputValue}
            />
            <p>{total} rows in Total</p>
          </div>
          <div>
            {pushNotifications === 0 && (
              <AppButton
                size={ButtonSize.TINY_BUTTON}
                disabled={loadTelemetryInProgress}
                testId="reload-button"
                handler={handleUpdate}
              >
                {updateIsOn ? (
                  "Stop Update"
                ) : (
                  <>
                    <div className={classes.buttonIcon}>
                      <AutorenewIcon />
                    </div>
                    Update
                  </>
                )}
              </AppButton>
            )}
          </div>
          {fileType === FileTypes.GPS ? (
            <div>
              <AppButton
                size={ButtonSize.TINY_BUTTON}
                progress={downloadInProgress}
                testId="download-gps-button"
                handler={handleDownload}
              >
                Download
              </AppButton>
            </div>
          ) : (
            <div>
              <ButtonGroup
                disabled={downloadInProgress}
                variant="contained"
                color="primary"
                ref={anchorRef}
                aria-label="split button"
                className={classes.buttonGroup}
              >
                <Button onClick={handleDownload} className={classes.downloadButton}>
                  {options[selectedDownloadOptionsIndex]}
                </Button>
                <Button
                  color="primary"
                  size="small"
                  aria-controls={openDownloadOptions ? "split-button-menu" : undefined}
                  aria-expanded={openDownloadOptions ? "true" : undefined}
                  aria-label="select download format"
                  aria-haspopup="menu"
                  onClick={handleToggle}
                >
                  <ArrowDropDownIcon />
                </Button>
              </ButtonGroup>
              <Popper
                style={{ zIndex: 9999 }}
                open={openDownloadOptions}
                anchorEl={anchorRef.current}
                role={undefined}
                transition={true}
                disablePortal={true}
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin: placement === "bottom" ? "center top" : "center bottom",
                    }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={handleClose}>
                        <MenuList id="split-button-menu">
                          {options.map((option, index) => (
                            <MenuItem
                              key={option}
                              disabled={index === 2}
                              selected={index === selectedDownloadOptionsIndex}
                              onClick={(event) => handleMenuItemClick(event, index)}
                            >
                              {option}
                            </MenuItem>
                          ))}
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </div>
          )}
        </div>
      </PaperComponent>
      <PaperComponent>
        {lastError && lastError.code === WRONG_TELEMETRY_FILE ? (
          <Typography variant="h6" align="center">
            {lastError?.message ?? "Unknown error"}
          </Typography>
        ) : (
          <div className={classes.tableWrapper}>
            {!telemetryStatus && loadTelemetryInProgress ? (
              <Preloader />
            ) : !loadTelemetryInProgress && telemetry.length ? (
              <>
                {!isEndUser && (
                  <AccordionComponent panelId="MAP" title="MAP" defaultExpanded={true}>
                    <MapContainer />
                  </AccordionComponent>
                )}

                <TableComponent
                  className={classes.tableContainer}
                  stickyHeader={true}
                  tableHead={
                    <>
                      {renderHeadRow(device, "device-row")}
                      {renderHeadRow(deviceelement, "device-element-row")}
                      {renderHeadRow(logvalues, "log-values-row")}
                    </>
                  }
                  tableBody={telemetry.map((telemetryRow: TelemetryEntry[], index) => {
                    const hasRowInvalidStatus = telemetryRow.find(
                      (item: TelemetryEntry) => item.value === "D_NOT_AVAILABLE"
                    );
                    return (
                      <TableRow key={index} className={hasRowInvalidStatus ? classes.invalidTelemetry : ""}>
                        {telemetryRow.map(({ unit, value }, cellIndex) => (
                          <TableCell key={cellIndex}>{`${
                            typeof value === "object" ? JSON.stringify(value) : value
                          } ${unit}`}</TableCell>
                        ))}
                      </TableRow>
                    );
                  })}
                />
              </>
            ) : (
              <Typography variant="h5" align="center">
                No data for telemetry
              </Typography>
            )}
          </div>
        )}
      </PaperComponent>
    </>
  );
};

export default TelemetryComponent;
